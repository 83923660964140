import type { GetStaticProps } from "next";
import Link from "next/link";
import notFound from "@/assets/lottie/not-found.json";
import { Button } from "@/components/ui/button";
import { fqdn } from "@/configs/siteConfigs";
import { Meta } from "@/features/seo/meta";
import { Main } from "@/features/ui/layouts/main";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";

interface Props {}

// Dynamically import Lottie Player to prevent SSR issues from Node v21+ exposing `navigator`
const Player = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false,
  },
);

export const NotFoundPage = () => {
  const { t } = useTranslation("common");

  return (
    <Main
      meta={
        <Meta
          title={t("page404.title")}
          description={t("page404.subtitle")}
          openGraph={{
            url: `${fqdn}/404`,
            title: t("page404.title"),
            description: t("page404.subtitle"),
          }}
        />
      }
    >
      <main className="grid min-h-full place-items-center bg-white px-4 py-24 sm:py-32 lg:px-4">
        <div className="text-center">
          <Player
            src={notFound}
            className="flex items-center justify-center"
            autoplay
            loop
          />
          <p className="text-brand-900 text-base font-semibold">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-700 sm:text-5xl">
            {t("page404.title")}
          </h1>
          <p className="mt-6 text-base leading-7 text-slate-700">
            {t("page404.subtitle")}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link href="/">
              <Button variant="default">{t("page404.goBack")}</Button>
            </Link>
          </div>
        </div>
      </main>
    </Main>
  );
};

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? "en", ["common"])),
  },
});

export default NotFoundPage;
